@import './styles/variables.scss';
@import './styles/mixins.scss';

.wrapper {
  padding: $padding*2;
}

.container__application {
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.container__middle {
  @include hw100();
  display: grid;
  place-items: center;

  .container__logo {
    max-width: 37rem;
    position: relative;

    // &::after {
    //   content: "NEW";
    //   display: block;
    //   position: absolute;
    //   right: 0.35rem;
    //   top: 5.4rem;
    //   background-color: #4ade80;
    //   color: #4ade80;
    //   color: $white;
    //   font-size: 1rem;
    //   line-height: 1;
    //   letter-spacing: 0.05rem;
    //   padding: 0.25rem 0.3rem 0.2rem 0.35rem;
    //   border-radius: 3px;
    //   animation: animAFTER 3s step-start infinite;
    //   animation-delay: 2s;
    //   opacity: 0;
    // }
  }
}

@keyframes animAFTER {
  33% {
    opacity: 1;
  }
}
