@import './variables.scss';

@mixin tablet-xs {
  @media (min-width: 600px) { @content; }
}

@mixin tablet-pt {
  @media (min-width: 768px) { @content; }
}

@mixin tablet-ls {
  @media (min-width: 1024px) { @content; }
}

@mixin desktop-sm {
  @media (min-width: 1200px) { @content; }
}

@mixin desktop {
  @media (min-width: 1440px) { @content; }
}

@mixin desktop-lg {
  @media (min-width: 1800px) { @content; }
}

@mixin black() {
  color: $black;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: normal;
}

@mixin white() {
  color: $white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin noScrollBar() {
  -ms-overflow-style: none;
  scrollbar-width: none;
  *::-webkit-scrollbar {
    display: none;
  }
}

@mixin noBounce () {
  overscroll-behavior: none;
}

@mixin transparent () {
  opacity: 0;
  z-index: -1;
  user-select: none;
  pointer-events: none;
}

@mixin hw100 () {
  height: 100%;
  width: 100%;
}