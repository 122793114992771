@import './fonts.scss';
@import './mixins.scss';
@import './variables.scss';

html {
  font-size: 62.5%;
  font-family: system-ui, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  min-height: -webkit-fill-available;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

body {
  @include black();
  @include noBounce();
  font-feature-settings: "kern";
}

*, ::after, ::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

// * {
//   @include noScrollBar();
// }

*::selection {
  @include white();
  background-color: $black;
}

*::placeholder {
  color: inherit;
  opacity:  1;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-size: inherit;
}

ul, li {
  list-style: none;
}

a {
  display: block;
  text-decoration: none;
  color: inherit;
  outline: 0;
  &:active,
  &:hover {
    outline: 0
  }
}

em {
  font-style: normal;
}

input {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  &:focus {
    outline: none;
  }
}

button {
  background-color: inherit;
  outline: none;
  border: none;
  cursor: pointer;
}

button,
input,
textarea,
section {
  font: inherit;
}