@import '../../styles/variables.scss';

.wrapper__form {
  width: 100%;

  .container__form {
    max-width: 37rem;
    margin: 0 auto;

    .container__input {
      width: 100%;

      .input--text {
        text-align: center;
        width: 100%;

        &::placeholder {
          opacity: 0.25;
        }
      }
    }
  }
}

.container__button {
  display: flex;
  justify-content: center;
}

.input--button {
  color: $white;
  background-color: $black;
  border-radius: 5px;
  -webkit-appearance: none;
  -webkit-border-radius: 5px;
  line-height: 1.2rem;
  padding: 0.5rem 0.5rem 0.4rem 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: #00dc2e;
  }
}

.input--button.c--black {
  &:hover {
    background-color: $black;
  }
}
.input--button.c--green {
  &:hover {
    background-color: #00dc2e;
  }
}

.pb-0 {
  margin-bottom: 0.4rem;
}
.pb-1 {
  margin-bottom: $padding / 2;
}