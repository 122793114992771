@font-face {
  font-family: 'SuisseIntl';
  src: url('../assets/fonts/SuisseIntl-Bold-WebXL.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('../assets/fonts/SuisseIntl-SemiBold-WebXL.woff2') format('woff2');
  font-weight: medium;
  font-style: normal;
}

body {
  font-family: 'SuisseIntl';
}