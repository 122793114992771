.small__font {
  font-size: 1.2rem !important;
  line-height: 1.2rem;
  font-weight: bold;
}

.tt--upps {
  text-transform: uppercase;
  letter-spacing: 0.035rem;
}
.tt--caps {
  text-transform: capitalize;
}
.ta--center {
 text-align: center;
}
.c--green {
  color: #00dc2e;
}
.c--grey {
  opacity: 0.25;

  &:hover {
    opacity: 1;
  }
}
.width--fit {
  width: fit-content;
}